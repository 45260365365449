<div class="modal-header">
  <h4 class="modal-title">{{ 'PAGES.PAGES_SEO_FORM.EDIT' | translate }} - {{ pageSeo?.name }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-0 d-flex flex-column">
  <form id="page-seo-form" (ngSubmit)="save()" [formGroup]="form" novalidate class="d-flex overflow-hidden flex-grow-1">
    <div class="locales-menu ps" [perfectScrollbar]>
      <div class="modal-section-title mt-2 mb-2 pl-2">
        {{ 'PAGES.PAGES_SEO_FORM.WEBSITES_LIST_TITLE' | translate }}
      </div>

      <div
        *ngFor="let websiteLocale of companyWebsiteLocales"
        (click)="selectCompanyWebSiteLocale(websiteLocale.id)"
        class="website-locale d-flex"
        [ngClass]="{ active: currentWebsiteLocaleId === websiteLocale.id }"
      >
        <div class="ico">
          <i class="fal fa-chevron-right"></i>
        </div>
        <div class="name">{{ websiteLocale.website.name }} - {{ websiteLocale.website.url }}</div>
        <div class="flag">
          <span class="flag-icon" [ngClass]="'flag-icon-' + websiteLocale.locale.locale"></span>
        </div>
      </div>
    </div>

    <div class="flex-grow-1 overflow-auto">
      <div class="locales-content-wrapper" formArrayName="websiteLocales">
        <div *ngIf="!currentWebsiteLocaleId" class="modal-section-title pl-3 mt-2">
          {{ 'PAGES.PAGES_SEO_FORM.SELECT_WEBSITE_MSG' | translate }}
        </div>

        <div *ngFor="let websiteLocale of form.get('websiteLocales')?.controls; let i = index" class="locales-content">
          <div
            [formGroupName]="i"
            *ngIf="currentWebsiteLocaleId === websiteLocale.get('company_website_locale_id').value"
          >
            <div class="modal-section-title mt-2 mb-2">
              {{ getWebsiteLocaleName(websiteLocale.get('company_website_locale_id').value) }}
            </div>

            <div class="form-group">
              <label>{{ 'PAGES.PAGES_SEO_FORM.TITLE' | translate }}</label>
              <input type="text" class="form-control" formControlName="title" />
            </div>

            <div class="form-group">
              <label>{{ 'PAGES.PAGES_SEO_FORM.HEADER_TITLE' | translate }}</label>
              <input type="text" class="form-control" formControlName="header_title" />
            </div>

            <div class="form-group">
              <label>{{ 'PAGES.PAGES_SEO_FORM.DESCRIPTION' | translate }}</label>
              <editor
                formControlName="description"
                [init]="{
                  plugins: 'table link autoresize',
                  base_url: '/assets/tinymce',
                  suffix: '.min',
                  height: 400
                }"
              ></editor>
            </div>

            <div class="form-group">
              <label>{{ 'PAGES.PAGES_SEO_FORM.SLUG' | translate }}</label>
              <input type="text" class="form-control" formControlName="slug" />
            </div>

            <div class="form-group">
              <label>{{ 'PAGES.PAGES_SEO_FORM.META_TITLE' | translate }}</label>
              <input type="text" class="form-control" formControlName="meta_title" />
            </div>

            <div class="form-group">
              <label>{{ 'PAGES.PAGES_SEO_FORM.META_DESCRIPTION' | translate }}</label>
              <input type="text" class="form-control" formControlName="meta_description" />
            </div>

            <div class="d-flex">
              <div class="custom-control custom-checkbox mr-3">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  formControlName="visible"
                  [attr.id]="'pages-seo-form-visible' + i"
                />
                <label class="custom-control-label" [attr.for]="'pages-seo-form-visible' + i">
                  {{ 'PAGES.PAGES_SEO_FORM.VISIBLE' | translate }}
                </label>
              </div>
            </div>

            <div class="d-flex">
              <div class="custom-control custom-checkbox mr-3">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  formControlName="indexable"
                  [attr.id]="'pages-seo-form-indexable' + i"
                />
                <label class="custom-control-label" [attr.for]="'pages-seo-form-indexable' + i">
                  {{ 'PAGES.PAGES_SEO_FORM.INDEXABLE' | translate }}
                </label>
              </div>
            </div>

            <!--  <div class="d-flex">
              <div class="custom-control custom-checkbox mr-3">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  formControlName="show_media"
                  [attr.id]="'pages-seo-form-show_media' + i"
                />
                <label class="custom-control-label" [attr.for]="'pages-seo-form-show_media' + i">
                  {{ 'PAGES.PAGES_SEO_FORM.SHOW_MEDIA' | translate }}
                </label>
              </div>
            </div> -->

            <div class="d-flex">
              <div class="custom-control custom-checkbox mr-3">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  formControlName="in_header_menu"
                  [attr.id]="'pages-seo-form-in_header_menu' + i"
                />
                <label class="custom-control-label" [attr.for]="'pages-seo-form-in_header_menu' + i">
                  {{ 'PAGES.PAGES_SEO_FORM.IN_HEADER_MENU' | translate }}
                </label>
              </div>
            </div>
            <div class="d-flex">
              <div class="custom-control custom-checkbox mr-3">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  formControlName="in_footer_help"
                  [attr.id]="'pages-seo-form-in_footer_help' + i"
                />
                <label class="custom-control-label" [attr.for]="'pages-seo-form-in_footer_help' + i">
                  {{ 'PAGES.PAGES_SEO_FORM.IN_FOOTER_HELP' | translate }}
                </label>
              </div>
            </div>
            <div class="d-flex">
              <div class="custom-control custom-checkbox mr-3">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  formControlName="in_footer_about"
                  [attr.id]="'pages-seo-form-in_footer_about' + i"
                />
                <label class="custom-control-label" [attr.for]="'pages-seo-form-in_footer_about' + i">
                  {{ 'PAGES.PAGES_SEO_FORM.IN_FOOTER_ABOUT' | translate }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-outline-dark btn-xs" form="page-seo-form" [disabled]="form.invalid">
    {{ 'PAGES.PAGES_SEO_FORM.SAVE' | translate }}
  </button>
</div>
