import { Component, OnInit } from '@angular/core';
import { AuthenticationService, Logger } from '@app/core';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { PaymentsService } from '@app/finance/payments/payments.service';
import { PaymentsFormService } from '@app/finance/payments/payments-form.service';
import { Payment } from '@app/finance/payments/payment.model';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceType } from '@app/finance/invoices/invoice-type.enum';
import { ClientabeType } from '@app/shared/components/clientable-select/clientabe-type.enum';
import { Room } from '@app/hotels/rooms/room.model';
import * as moment from 'moment';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { PymentableType } from '@app/finance/payments/paymentable-type.enum';
import { UserRole } from '@app/settings/users/roles/user-role.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { AccessService } from '@app/core/access/access.service';
import { PaymentsTypes } from '@app/finance/payments/payments-types.enum';

const log = new Logger('HotelsBookingsPaymentsListComponent');

@Component({
  selector: 'app-hotels-bookings-payments-list',
  templateUrl: './hotels-bookings-payments-list.component.html',
  styleUrls: ['./hotels-bookings-payments-list.component.scss'],
})
export class HotelsBookingsPaymentsListComponent extends KendoGridComponent implements OnInit {
  paymentsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  LaravelModels = LaravelModelsEnum;
  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;
  InvoiceType = InvoiceType;
  ClientableType = ClientabeType;
  UserRoleType = UserRoleType;
  paymentTypes = PaymentsTypes;

  constructor(
    private paymentsService: PaymentsService,
    private paymentsFormService: PaymentsFormService,
    protected kendoGridService: KendoGridService,
    private route: ActivatedRoute,
    private router: Router,
    public accessService: AccessService,
    private auth: AuthenticationService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.state = CustomKendoFilter.getStateFromStorage('HotelsBookingsPaymentsListComponent');

      this.loadData(this.state);
    });
  }

  loadData(state: DataStateChangeEvent = null) {
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addFilter('paymentable_type', PymentableType.HOTELBOOKING)
      .addIncludes(
        'bank_account',
        'company',
        'payment_method',
        'type',
        'clientable',
        'currency',
        'fee_currency',
        'refundable',
        'paymentable.supplier',
        'paymentable.events',
        'paymentable.hotel',
        'paymentable.rooms',
        'paymentable.company',
        'paymentable.reservation_agent',
        'paymentable.contracting_agent',
        'paymentable.rooms_earliest'
      );

    // проверява дали юзъра има определени роли, но не админ
    if (!this.accessService.hasAccess([], [])) {
      this.auth.accessToken.profile.roles.forEach((role: UserRole) => {
        if (role.id === UserRoleType.RESERVATION_AGENT || role.id === UserRoleType.CONTRACTING_AGENT) {
          this.apiQuery.addFilter('user_id', this.auth.accessToken.profile.id);
        }
      });
    }

    this.paymentsService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.paymentsResponse = response;
      this.data = response.getGridData();

      // this.paymentsResponse.data.forEach((item: any) => {
      //   if (!item.paymentable || !item.paymentable.rooms) {
      //     return false;
      //   }

      //   item.paymentable.earliest_check_in = moment
      //     .min(item.paymentable.rooms.map((room: Room) => moment(room.check_in)))
      //     .format(this.env.apiPipeDateFormat);
      // });
    });

    CustomKendoFilter.pushStateInStorage('HotelsBookingsPaymentsListComponent', this.state);
  }

  edit(payment: Payment): void {
    this.paymentsFormService.open(payment).then(
      (res) => {
        this.loadData(this.state);
      },
      (res) => {
        log.info('modal closed');
      }
    );
  }

  delete(payment: Payment): void {
    this.paymentsService.destroy(payment.id).subscribe(() => {
      this.loadData(this.state);
    });
  }

  add() {
    this.paymentsFormService.open().then(
      (response) => {
        this.loadData(this.state);
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  clearFilters() {
    this.router.navigate(['/hotel-bookings-payments']);
    this.apiQuery = new ApiQuery();
    this.loadData();
  }

  test(logg: any) {
    log.debug(logg);
  }
}
