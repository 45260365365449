<div class="container-fluid" *ngIf="accessService.hasAccess([], ['sales.menu'])">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">
        {{ 'REPORTS.CLOSED_SALES.TITLE' | translate }}
      </h5>
    </div>

    <div class="d-flex align-items-center">
      <div>
        <button type="button" class="btn btn-link" (click)="clearFilters()">
          {{ 'CLEAR_FILTERS' | translate }}
        </button>
      </div>
    </div>
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="requestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Closed_sales', grid)"
    [loading]="loader"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'REPORTS.CLOSED_SALES.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'REPORTS.CLOSED_SALES.ID' | translate }}"
      filter="numeric"
      [width]="120"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="notSoldActivity.created_at"
      title="{{ 'REPORTS.CLOSED_SALES.DATE_TIME' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('notSoldActivity.created_at')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.not_sold_activity?.created_at | date : env.pipeDateTimeFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="company.name"
      title="{{ 'REPORTS.CLOSED_SALES.COMPANY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('company.name')"
      [width]="100"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/settings/companies/view']" [queryParams]="{ id: dataItem?.company_id }" target="_blank">
          {{ dataItem?.company?.shortName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="client.name"
      title="{{ 'REPORTS.CLOSED_SALES.CLIENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('client.name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-client-select [filter]="filter"></app-kendo-client-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span
          *ngIf="dataItem.client?.country && dataItem.client?.country?.code"
          class="flag-icon"
          [ngClass]="'flag-icon-' + (dataItem.client?.country?.code | lowercase)"
        >
        </span>

        <a [routerLink]="['/sales/view']" [queryParams]="{ id: dataItem?.id }" target="_blank">
          {{ dataItem.client?.name }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.formattedName"
      title="{{ 'REPORTS.CLOSED_SALES.EVENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('event.formattedName')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select [filter]="filter"></app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem?.event?.id }" target="_blank">
          {{ dataItem?.event?.formattedName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="status_reason"
      title="{{ 'REPORTS.CLOSED_SALES.STATUS_REASON' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('status_reason')"
    ></kendo-grid-column>

    <kendo-grid-column
      field="sales_agent.fake_first_name"
      title="{{ 'REPORTS.CLOSED_SALES.SALES_AGENT_AND_SALES_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sales_agent.fake_first_name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.SALES_AGENT, UserRoleType.SALES_MANAGER]"
          [field]="'sales_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.sales_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="reservation_agent.fake_first_name"
      title="{{ 'REPORTS.CLOSED_SALES.RESERVATION_AGENT_AND_RESERVATION_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('reservation_agent.fake_first_name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.RESERVATION_AGENT, UserRoleType.RESERVATION_MANAGER]"
          [field]="'reservation_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.reservation_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="customer_care_agent.fake_first_name"
      title="{{ 'REPORTS.CLOSED_SALES.CUSTOMER_CARE_AGENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('customer_care_agent.fake_first_name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="UserRoleType.CUSTOMER_CARE_AGENT"
          [field]="'customer_care_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.customer_care_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="status_reason"
      title="{{ 'REPORTS.CLOSED_SALES.STATUS_REASON' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('status_reason')"
    >
      <!--      <ng-template kendoGridFilterCellTemplate let-filter>-->
      <!--        <app-kendo-user-select-->
      <!--          [filter]="filter"-->
      <!--          [roleType]="UserRoleType.CUSTOMER_CARE_AGENT"-->
      <!--          [field]="'customer_care_agent_id'"-->
      <!--        ></app-kendo-user-select>-->
      <!--      </ng-template>-->

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.status_reason }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="created_at"
      title="{{ 'REPORTS.CLOSED_SALES.CREATED_AT' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('created_at', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.created_at.getDate() }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'REPORTS.CLOSED_SALES.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-sale-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.MARKETING,
                UserRoleType.EXTERNAL_PARTNER_LEAD
              ],
              ['sales.show']
            )
          "
          [link]="['/sales/view']"
          [params]="{ id: dataItem.id }"
        >
        </app-sale-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
