import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FilterService } from '@progress/kendo-angular-grid';
import { FormBuilder } from '@angular/forms';
import { Logger } from '@app/core';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';

const log = new Logger('KendoPageSelectComponent');

@Component({
  selector: 'app-kendo-page-select',
  templateUrl: './kendo-page-select.component.html',
  styleUrls: ['./kendo-page-select.component.scss'],
})
export class KendoPageSelectComponent extends CustomKendoFilter implements OnInit, OnChanges {
  @Input() field = 'parent_id';

  constructor(private formBuilder: FormBuilder, filterService: FilterService) {
    super(filterService);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      parent_id: [null],
      page: [null],
    });

    const page = this.getObjectFromStorage(this.field, 'pages');

    if (page) {
      setTimeout(() => {
        this.form.patchValue({
          id: page.id,
          page: page,
          parent_id: page.id,
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Изчиства формата
    // Използва се, за да чисти филтрите, когато се сетне null state на таблицата
    if (this.form && changes && changes.filter && !changes.filter.currentValue) {
      this.form.reset();
    }
  }

  onSelect(event: any) {
    CustomKendoFilter.pushObjectInStorage(event, 'pages');

    this.filter = this.removeFilter(this.field);

    const filters = [];
    if (event) {
      filters.push({
        field: this.field,
        operator: 'eq',
        value: event.id,
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: [],
    };

    if (filters.length) {
      root.filters.push(...filters);
    }
    this.filterService.filter(root);
    log.debug('filters', filters);
  }
}
