import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { Reminder } from '@app/reminders/reminders/reminder.model';
import { AuthenticationService, Logger } from '@app/core';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { RemindersService } from '@app/reminders/reminders/reminders.service';
import { RemindersFormService } from '@app/reminders/reminders/reminders-form.service';
import { UserRole } from '@app/settings/users/roles/user-role.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSet } from '@app/shared/kendo/data-set';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';

const log = new Logger('RemindersListComponent');

@Component({
  selector: 'app-reminders-list',
  templateUrl: './reminders-list.component.html',
  styleUrls: ['./reminders-list.component.scss'],
})
export class RemindersListComponent extends KendoGridComponent implements OnInit {
  remindersResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  isCurrentUserAdmin = false;
  canViewList = false;

  constructor(
    private reminderService: RemindersService,
    private remindersFormService: RemindersFormService,
    protected kendoGridService: KendoGridService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.auth.accessToken.profile.roles.forEach((role: UserRole) => {
      if (role.id === UserRoleType.ADMINISTRATOR || role.id === UserRoleType.RESERVATION_MANAGER) {
        this.canViewList = true;
      }
      if (role.id === UserRoleType.ADMINISTRATOR) {
        this.isCurrentUserAdmin = true;
      }
    });

    this.route.queryParamMap.subscribe((params) => {
      //   this.state = null;
      this.state = new DataSet().addFilter('completed', false);

      const stateFromStorage = CustomKendoFilter.getStateFromStorage('RemindersListComponent');
      if (stateFromStorage) {
        this.state = stateFromStorage;
      }

      if (params.get('dataSet')) {
        this.state = DataSet.decodeFromJson(params.get('dataSet'));
      }

      this.loadData(this.state);
    });
  }

  /**
   * Load data from API
   */
  loadData(state: DataStateChangeEvent = null) {
    this.state = state;

    this.apiQuery.setDataSetFilters(state).addIncludes('user', 'reminderable');

    // Filter reminders if the user isn't an administrator
    if (!this.canViewList) {
      this.apiQuery.addFilter('user_id', this.auth.accessToken.profile.id);
    }

    this.reminderService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      log.debug('response', response);
      this.remindersResponse = response;
      this.data = response.getGridData();
    });

    CustomKendoFilter.pushStateInStorage('RemindersListComponent', this.state);
  }

  /**
   * Edit reminder
   *
   * @param reminder Reminder object
   */
  edit(reminder: Reminder) {
    this.remindersFormService.open(reminder).then((res) => {
      this.loadData(this.state);
    });
  }

  /**
   * Delete reminder
   *
   * @param reminder Reminder object
   */
  delete(reminder: Reminder) {
    this.reminderService.destroy(reminder.id).subscribe(() => {
      this.loadData(this.state);
    });
  }

  clearFilters() {
    this.router.navigate(['/reminders']);

    this.apiQuery = new ApiQuery();
    this.loadData();
  }
}
