import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Page } from '@app/pages/pages/pages.model';
import { PagesSeoFormComponent } from '@app/pages/pages/pages-seo-form/pages-seo-form.component';

@Injectable({
  providedIn: 'root',
})
export class PagesSeoFormService {
  constructor(private modalService: NgbModal) {}

  public open(page: null | Page = null): Promise<any> {
    const modal = this.modalService.open(PagesSeoFormComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.pageSeo = page;
    return modal.result;
  }
}
