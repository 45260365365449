import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@app/core';
import { EventsService } from '@app/events/events/events.service';
import { Events } from '@app/events/events/events.model';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { EventsFormService } from '@app/events/events/events-form.service';
import { HotelBooking } from '@app/hotels/hotel-bookings/hotel-booking.model';
import { HotelBookingsFormService } from '@app/hotels/hotel-bookings/hotel-bookings-form.service';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { Room } from '@app/hotels/rooms/room.model';
import { EventType } from '@app/events/events/event-type.enum';
import { ActivitiesService } from '@app/shared/components/activities/activities.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DistanceService } from '@app/core/distance/distance.service';
import { ToastrService } from 'ngx-toastr';
import { Hotel } from '@app/hotels/hotels/hotel.model';
import { HotelsService } from '@app/hotels/hotels/hotels.service';
import { Title } from '@angular/platform-browser';
import { DataSet } from '@app/shared/kendo/data-set';
import { BookingPropertiesType } from '@app/settings/types/booking-properties-type.enum';
import { AccessService } from '@app/core/access/access.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { EventsSeoFormService } from '@app/events/events/events-seo-form.service';
import LatLng = google.maps.LatLng;

import TravelMode = google.maps.TravelMode;

const log = new Logger('EventsViewComponent');

@Component({
  selector: 'app-events-view',
  templateUrl: './events-view.component.html',
  styleUrls: ['./events-view.component.scss'],
})
export class EventsViewComponent extends KendoGridComponent implements OnInit, OnDestroy {
  LaravelModel = LaravelModelsEnum;
  eventId: number;
  event: Events;
  hotelBookingsRequestsResponse: PaginatedResponse;
  hotelBookingsData: GridDataResult;
  hotelBookingsState: DataStateChangeEvent;
  hotelBookingsApiQuery: ApiQuery = new ApiQuery();
  env = environment;
  eventTypes = EventType;
  form: FormGroup;

  direction: any = false;
  markerOptions: {
    origin: {
      icon: 'assets/map_marker2.png';
    };
    destination: {
      icon: 'assets/map_marker.png';
    };
  };
  renderOptions = {
    suppressMarkers: true,
  };

  TravelMode = TravelMode;
  gMapsResults: any = null;
  hotels: Array<Hotel> = [];

  UserRoleType = UserRoleType;

  @ViewChild('perfectScroll', { static: true }) perfectScroll: PerfectScrollbarDirective;

  constructor(
    public activitiesService: ActivitiesService,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private hotelBookingService: HotelBookingsService,
    private eventsFormService: EventsFormService,
    private hotelBookingsFormService: HotelBookingsFormService,
    protected kendoGridService: KendoGridService,
    private formBuilder: FormBuilder,
    private distanceService: DistanceService,
    private toastr: ToastrService,
    private hotelsService: HotelsService,
    private titleService: Title,
    public accessService: AccessService,
    private eventSeoFormService: EventsSeoFormService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.hotelBookingsState = new DataSet().addFilter('booking.property_id', BookingPropertiesType.ADV);

        this.eventId = Number(params.get('id'));
        this.createForm();
        this.loadData();
        this.loadHotels();
        this.activitiesService.auto(LaravelModelsEnum.EVENT, this.eventId, false);
      } else {
        log.error('Id is not found in route params!');
      }
    });
  }

  ngOnDestroy(): void {
    // крие sidebar-a s activity-тата и съобщенията
    this.activitiesService.hide();
  }

  loadData(): void {
    const apiQuery: ApiQuery = new ApiQuery().addIncludes(
      'event_group',
      'event_group.repeat',
      'venue.city.country',
      'industry',
      'city.country',
      'type',
      'adv_hotel_bookings_count',
      'adv_hotel_bookings_rooms_count',
      'adv_canceled_rooms_count',
      'sold_rooms_count',
      'blocked_rooms_count'
    );
    this.eventsService.show(this.eventId, apiQuery).subscribe((event: Events) => {
      this.event = event;
      log.debug('this.event', this.event);

      if (this.event.name) {
        this.titleService.setTitle(this.event.name);
      }

      this.hotelBookingsLoadDataTable(this.hotelBookingsState);
    });
  }

  /**
   * Отваря sidebar-a s activity-тата и съобщенията
   */
  openActivities(): void {
    this.activitiesService.show(LaravelModelsEnum.EVENT, this.eventId, false);
  }

  hotelBookingsLoadDataTable(state: DataStateChangeEvent = null) {
    log.debug('hotelBookingsLoadDataTable');
    this.hotelBookingsState = state;
    this.hotelBookingsApiQuery
      .addFilters({ event_id: this.eventId })
      .setDataSetFilters(state)
      .addIncludes(
        'booking',
        'booking.events',
        'booking.supplier',
        'booking.company',
        'booking.property',
        'booking.cancellation_terms',
        'booking.tags',
        'type',
        'meal_plan',
        'nights',
        'nights.price_currency',
        'nights.net_price_currency',
        'hotel.city.country',
        'hotel.venue'
      )
      .addFilter('full_available', true);

    this.hotelBookingService.indexRooms(this.hotelBookingsApiQuery).subscribe((response: PaginatedResponse) => {
      this.hotelBookingsRequestsResponse = response;
      this.hotelBookingsData = response.getGridData();
      this.perfectScroll.update();
    });
  }

  edit(): void {
    this.eventsFormService.open(this.event).then((response) => {
      log.debug('res', response);
      this.loadData();
    });
  }

  addBooking(): void {
    const newBooking = new HotelBooking().deserialize({
      events: [this.event],
      rooms: [
        {
          check_in: this.event.date_from,
          check_out: this.event.date_to,
          fake_net_price: null,
          fake_price: null,
          fake_web_price: null,
        },
      ],
    });

    this.hotelBookingsFormService.open(newBooking).then(
      (response) => {
        this.loadData();
        this.loadHotels();
        this.hotelBookingsLoadDataTable(this.hotelBookingsState);
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  hotelBookingsDelete(hotelBooking: HotelBooking) {
    this.hotelBookingService.destroy(hotelBooking.id).subscribe(() => {
      this.hotelBookingsLoadDataTable(this.hotelBookingsState);
    });
  }

  hotelBookingsEdit(hotelBooking: HotelBooking) {
    this.hotelBookingsFormService.open(hotelBooking).then(
      (res) => {
        log.debug(res);
        this.hotelBookingsLoadDataTable(this.hotelBookingsState);
      },
      (err) => {
        log.debug(err);
      }
    );
  }

  hotelsSearch() {
    setTimeout(() => {
      this.direction = false;

      if (
        this.form.get('hotel').value &&
        this.form.get('hotel').value.lat &&
        this.form.get('hotel').value &&
        this.form.get('hotel').value.long
      ) {
        this.direction = {
          origin: new LatLng(this.event.venue.lat, this.event.venue.long),
          destination: new LatLng(this.form.get('hotel').value.lat, this.form.get('hotel').value.long),
        };

        this.distanceService
          .getDistance(
            {
              lat: this.form.get('hotel').value.lat,
              long: this.form.get('hotel').value.long,
            },
            {
              lat: this.event.venue.lat,
              long: this.event.venue.long,
            },
            this.form.get('transport_type').value
          )
          .subscribe(
            (response: any) => {
              log.debug('res', response);
              this.gMapsResults = response.rows[0].elements[0];
            },
            (error) => {
              log.error(error);
              this.toastr.error(error);
              this.gMapsResults = null;
              this.direction = false;
            }
          );
      } else {
        this.direction = false;
      }
    });
  }

  /**
   *  Парсва стрингосани числа
   * @param number Подава се стрингосано число или число
   */
  number(number: any): number {
    return Number(number);
  }

  selectHotel(hotelId: number) {
    log.debug('hotelid', hotelId);
    this.hotels.forEach((hotel: Hotel) => {
      if (hotel.id === hotelId) {
        this.form.get('hotel').patchValue(hotel);
        this.hotelsSearch();
      }
    });
  }

  editSeo(event: Events): void {
    this.eventSeoFormService.open(event);
  }

  private createForm() {
    this.form = this.formBuilder.group({
      // hotel_id: [null, [Validators.required]],
      hotel: [null],
      transport_type: [TravelMode.DRIVING, [Validators.required]],
    });
  }

  private loadHotels() {
    const query = new ApiQuery();
    query
      .addIncludes('city.country', 'venues')
      .addFilter('bookings_for_event_id', this.eventId)
      .addFilter('active', true)
      .setLimit(99999);

    this.hotelsService.index(query).subscribe((hotels: PaginatedResponse) => {
      this.hotels = hotels.data;
    });
  }
}
